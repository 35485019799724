exports.components = {
  "component---src-pages-academy-js": () => import("./../../../src/pages/academy.js" /* webpackChunkName: "component---src-pages-academy-js" */),
  "component---src-pages-association-js": () => import("./../../../src/pages/association.js" /* webpackChunkName: "component---src-pages-association-js" */),
  "component---src-pages-beatus-bulla-first-js": () => import("./../../../src/pages/beatus-bulla-first.js" /* webpackChunkName: "component---src-pages-beatus-bulla-first-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-ficus-js": () => import("./../../../src/pages/ficus.js" /* webpackChunkName: "component---src-pages-ficus-js" */),
  "component---src-pages-index-church-js": () => import("./../../../src/pages/index/church.js" /* webpackChunkName: "component---src-pages-index-church-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infusion-first-js": () => import("./../../../src/pages/infusion-first.js" /* webpackChunkName: "component---src-pages-infusion-first-js" */),
  "component---src-pages-medias-js": () => import("./../../../src/pages/medias.js" /* webpackChunkName: "component---src-pages-medias-js" */),
  "component---src-pages-partitions-js": () => import("./../../../src/pages/partitions.js" /* webpackChunkName: "component---src-pages-partitions-js" */),
  "component---src-pages-quartier-libre-js": () => import("./../../../src/pages/quartier-libre.js" /* webpackChunkName: "component---src-pages-quartier-libre-js" */),
  "component---src-pages-social-js": () => import("./../../../src/pages/social.js" /* webpackChunkName: "component---src-pages-social-js" */)
}

